function toggleMenu() {
	const menu = document.querySelector(".menu");
	const hamburger = document.querySelector(".hamburger");
	menu.classList.toggle("is-active");
	hamburger.classList.toggle("is-active");
}
document.addEventListener("DOMContentLoaded", () => {
	const logoutButton = document.getElementById("logout-button");
	const loginLink = document.getElementById("login-link");
	const authButtonContainer = document.getElementById(
		"auth-button-container"
	);

	const userId = localStorage.getItem("klaviyo_api_user_id");

	if (userId) {
		// Show logout button and hide login link
		logoutButton.style.display = "block";
		loginLink.style.display = "none";

		logoutButton.addEventListener("click", async () => {
			try {
				const response = await fetch("/auth/logout", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
				});

				const data = await response.json();
				console.log("response:", data);

				if (data.message === "Logged out") {
					localStorage.removeItem("klaviyo_api_user_id");
					window.location.href = "/login/";
				}
			} catch (error) {
				console.error("Error:", error);
			}
		});
	} else {
		// Hide logout button and show login link
		logoutButton.style.display = "none";
		loginLink.style.display = "block";
	}
});
